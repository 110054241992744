.reportLibraryWrapper {
    display: block;
    padding: 8px;
    .motif-progress-loader-container {
        z-index: 2;
    }
    .motif-input {
        &:focus-visible {
            outline: 1px solid #C3C3CB !important;
        }
    }
    .breadCrumbs {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 16px;
    }
    .heading {
        margin-bottom: 24px;
        .title {
            font-size: 28px;
            font-weight: 400;
            margin-bottom: 8px;
        }
        .description {
            font-size: 16px;
            opacity: 0.6;
        }
    }
    .reportLibrary__content {
        background-color: white;
        .motif-form-field {
            margin-bottom: 0;
        }
        input.motif-input {
            border: none;
            &:focus {
                border: none;
            }
        }
        .reportLibrary__tableWrapper {
            width: 100%;
            // .motif-table .ag-row {
            //     transform: unset !important;
            //     position: static;
            //     height: 72px !important;
            // }
            // .motif-table .ag-center-cols-container {
            //     height: 72px !important;
            // }
            .motif-table .ag-cell:hover {
                background: transparent;
            }
            .reportLibraryActions {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .cellReportName {
                max-width: 600px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.modal {
   &__wrapper {
      position: absolute;
      flex-direction: row;
      font-size: 14px;
      color: #23232F;
      line-height: 24px;
      z-index: 99;
      overflow-x: hidden;
      top: 35%;
      background-color: #FFFFFF;
      left: 50%;
      transform: translateX(-50%);
      width: 500px;
   }
   &__header {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
   }
   &__headerWrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 20px 24px 28px 20px;
   }
   &__close {
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
   }
   &__content {
      padding: 16px 32px;
      border-top: 1px solid #E8E8EC;
   }
   .button-wrap {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      &__ok {
         margin-left: 12px;
         &--delete {
            background-color: red;
            border: none;
         }
      }
   }
   .bglayoutHide {
      display: none;
   }
   .bglayoutShow {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      transition: background-color 0.5s;
      z-index: 9;
      opacity: 0.5;
      display: block;
   }
}

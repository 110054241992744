.reportSectionWrapper {
    padding: 16px;
    padding-top: 0;
    .reportSection {
        .reportSectionCopy {
            text-align: end;
            .copyIcon {
                margin-right: 8px;
                width: 16px;
            }
        }
        .reportSectionContent {
            .sectionPrompt {
                font-weight: 400;
                margin-top: 32px;
                &__header {
                    display: flex;
                    &__text {
                        margin-right: 4px;
                    }
                    &__icon {
                        width: 16px;
                    }
                }
            }
        }
        .randomnessWrapper {
            border-bottom: 1px solid #E8E8EC;
            .randomness {
                font-weight: 400;
                margin-top: 32px;
                max-width: 400px;
                &__header {
                    display: flex;
                    justify-content: space-between;
                    padding-right: 8px;
                    &__icon {
                        width: 16px;
                    }
                }
                &__control {
                    .motif-slider-value-wrap {
                        display: none;
                    }
                    .motif-form-field  {
                        margin-bottom: 0;
                    }
                    .sliderValue {
                        font-size: 20px;
                        font-weight: 400;
                        padding-bottom: 32px;
                    }
                }
            }
        }
        .submitSectionWrapper {
            padding: 16px;
            display: flex;
            justify-content: end;;
        }
    }
}
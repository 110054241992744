.headerWrapper {
    .header__avatar {
        background: transparent;
        border: none;
    }
    .eyLogo {
        margin-left: 16px;
        margin-top: -5px;
    }
    .motif-header .motif-header-vertical-navigation-open-button .motif-icon-button {
        align-items: center;
    }
}
.motif-dropdown-menu-portal {
    .header {
        &__displayname {
            margin-left: 16px;
        }
        &__item {
            &__role {
                font-weight: bold;
                margin-right: 12px;
            }
        }
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0;
    padding: 0;
    .content {
        display: flex;
        .main {
            padding: 16px;
            height: calc(100vh - 60px);
            overflow: auto;
            width: 100%;
            background-color: #f6f5fa;
        }
    }
    .motif-form-field-text-input .motif-label + .motif-input-component .motif-input {
        &:focus-visible {
            border: none;
        }
    }
}

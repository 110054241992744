.sideNavWrapper {
    background-color: 'lightgray';
    display: flex;
    margin: 0;
    overflow: hidden;
    padding: 0;
    .motif-vertical-navigation-menuitem.motif-vertical-navigation-menuitem-selected {
        background: #E6E6E9;
    }
    .motif-vertical-navigation-menuitem .motif-vertical-navigation-menuitem-label {
        color: #2E2E38;
    }
}
.collapsedWidth {
    width: 54px;
    border-right: 1px solid #E6E6E9;
}

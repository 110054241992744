.modal {
    .deleteModalHeader {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        &__icon {
            color: red;
            background-color: #FCF5F3;
            padding: 12px;
            margin-bottom: 16px;
            display: inline-flex;
            border-radius: 50%;
        }
    }
    .deleteModalContent {
        &__text {
            font-size: 16px;
        }
    }
}
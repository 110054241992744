.modal {
    &__wrapper {
        .saveModalHeader {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            &__icon {
                color: green;
                background-color: #E7FCE8;
                padding: 12px;
                margin-bottom: 16px;
                display: inline-flex;
                border-radius: 50%;
            }
        }
        .saveModalContent {
            &__label {
                font-size: 14px;
                font-weight: 500;
                opacity: 0.6;
                margin-bottom: 8px;
            }
            &__text {
                margin-bottom: 8px;
            }
        }
    }
}
.modal {
    .editModalHeader {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        &__icon {
            color: #1275C1;
            background-color: #F4F7FC;
            padding: 12px;
            margin-bottom: 16px;
            display: inline-flex;
            border-radius: 50%;
        }
    }
    .editModalContent {
        &__text {
            font-size: 16px;
        }
    }
}
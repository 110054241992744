.reportDetails {
    padding: 16px 32px;
    display: grid;
    grid-template-columns: 40% 10% 10% 10%;
    column-gap: 5%;
    border-bottom: 1px solid #E8E8EC;
    &__item {
        &__title {
            font-size: 12px;
            font-weight: 400;
            opacity: 0.6;
        }
        &__text {
            font-weight: 400;
        }
    }
}
.reportGeneratorWrapper {
    .motif-input, .motif-text-area, .dropzone-container {
        &:focus-visible {
            outline: 1px solid #C3C3CB !important;
        }
    }
    .motif-progress-loader-container {
        z-index: 2;
    }
    display: block;
    padding: 8px;
    .breadCrumbs {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 16px;
    }
    .heading {
        margin-bottom: 24px;
        .title {
            font-size: 28px;
            font-weight: 400;
            margin-bottom: 8px;
        }
        .description {
            font-size: 16px;
            opacity: 0.6;
            // color: #656579;
        }
    }
    .report__generator__content {
        background-color: white;
        .buttonText {
            margin-top: -4px;
        }
        .inputWrapper {
            width: 100%;
            padding: 16px;
            border-bottom: 1px solid #E8E8EC;
            &__heading {
                width: 100%;
                &__title {
                    font-weight: 700;
                    font-size: 18px;
                    margin-bottom: 8px;
                }
                &__description {
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    opacity: 0.6;
                    // color: #656579;
                }
                .actions {
                    display: flex;
                    justify-content: end;
                    padding: 0 8px;
                    .saveReport {
                        margin-right: 24px;
                    }
                    .saveReport, .downloadReport {
                        font-weight: 400;
                    }
                }
            }
        }
        .datasourceWrapper {
            // background-color: #E6E6E9;
            padding: 16px;
            min-height: 400px;
            border-bottom: 1px solid #E8E8EC;
            .addDatasourceWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                &__title {
                    font-weight: 500;
                }
                &__desc {
                    font-weight: 300;
                }
                .saveIcon {
                    background-color: #F3F3F5;
                    width: 36px;
                    height: 36px;
                    padding: 8px;
                    display: flex;
                    border-radius: 50%;
                    justify-content: center;
                    align-items: center;
                }
                .addDatasource {
                    margin-top: 16px;
                }
            }
            .datasourceItemsWrapper {
                .datasourceItemWrapper {
                    padding: 8px 16px;
                    background-color: #E6E6E9;
                    margin-bottom: 4px;
                    .datasourceItem__type {
                        display: flex;
                        justify-content: space-between;
                        .inputType {
                            .longTextInput {
                                .motif-input {
                                    min-height: 200px;
                                }
                            }
                            .motif-form-field {
                                margin-bottom: 0;
                            }
                            width: 60vw;
                        }
                        .linkInfo {
                            position: relative;
                            display: flex;
                            align-items: center;
                            left: -5%;
                            top: 2px;
                            width: 16px;
                            opacity: 0.8;
                        }
                        .process {
                            display: flex;
                            .processState {
                                display: flex;
                                align-items: center;
                                .uploadIcon, .processedIcon {
                                    width: 16px;
                                    margin-right: 8px;
                                }
                                .processedIcon {
                                    color: green;
                                }
                                .errorState {
                                    color: red;
                                    width: 16px;
                                    position: relative;
                                    top: 2px;
                                }
                                .processingSpinner {
                                    border: 2px solid #D7D7DC; /* Light grey */
                                    border-top: 2px solid #2E2E38; /* Blue */
                                    border-right: 2px solid #2E2E38; /* Blue */
                                    border-radius: 50%;
                                    width: 20px;
                                    height: 20px;
                                    animation: spin 1s linear infinite;
                                    margin-right: 8px;
                                }
                            }
                        }
                        .deleteDatasource {
                            display: flex;
                        }
                    }
                }
                .domainNameWrapper {
                    margin-top: 16px;
                }
            }
        }
        .submitWrapper {
            border-bottom: 1px solid #E8E8EC;
            padding: 16px;
            display: flex;
            justify-content: space-between;
        }
        .reportSectionWrapper {
            .reportSectionHeader {
                padding: 32px;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
}

.reportLibrary__tableWrapper {
    width: 100%;
    .motif-table .ag-cell:hover {
        background: transparent;
    }
    .reportLibraryActions {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
}

.toastWrapper {
    .motif-toast-fixed-top, .motif-toast-fixed-bottom {
        position: absolute;
        z-index: 11;
        width: 30%;
        right: 1%;
        left: unset;
    }
    .motif-toast-icon-container {
        display: none;
    }
    .motif-toast-text-container {
        margin-left: 24px;
        padding: 12px 16px;
    }
    .motif-toast-content {
        align-items: start;
        justify-content: space-around;
    }
    .motif-toast-close-button {
        margin-top: 8px;
    }
    .toastHeader {
        font-weight: 400;
    }
    .toastContent {
        font-weight: 300;
    }
}
